exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collection-build-a-modern-data-stack-js": () => import("./../../../src/pages/collection-build-a-modern-data-stack.js" /* webpackChunkName: "component---src-pages-collection-build-a-modern-data-stack-js" */),
  "component---src-pages-example-gallery-js": () => import("./../../../src/pages/example-gallery.js" /* webpackChunkName: "component---src-pages-example-gallery-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-maturity-model-assessment-form-js": () => import("./../../../src/pages/maturity-model-assessment-form.js" /* webpackChunkName: "component---src-pages-maturity-model-assessment-form-js" */),
  "component---src-pages-maturity-model-assessment-js": () => import("./../../../src/pages/maturity-model-assessment.js" /* webpackChunkName: "component---src-pages-maturity-model-assessment-js" */),
  "component---src-pages-python-tutorial-index-js": () => import("./../../../src/pages/python-tutorial/index.js" /* webpackChunkName: "component---src-pages-python-tutorial-index-js" */),
  "component---src-pages-python-tutorial-libraries-js": () => import("./../../../src/pages/python-tutorial/libraries.js" /* webpackChunkName: "component---src-pages-python-tutorial-libraries-js" */),
  "component---src-pages-report-themes-js": () => import("./../../../src/pages/report-themes.js" /* webpackChunkName: "component---src-pages-report-themes-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-roi-calculator-js": () => import("./../../../src/pages/roi-calculator.js" /* webpackChunkName: "component---src-pages-roi-calculator-js" */),
  "component---src-pages-sql-tutorial-js": () => import("./../../../src/pages/sql-tutorial.js" /* webpackChunkName: "component---src-pages-sql-tutorial-js" */),
  "component---src-pages-visual-explorer-guides-js": () => import("./../../../src/pages/visual-explorer-guides.js" /* webpackChunkName: "component---src-pages-visual-explorer-guides-js" */),
  "component---src-templates-gallery-template-js": () => import("./../../../src/templates/galleryTemplate.js" /* webpackChunkName: "component---src-templates-gallery-template-js" */),
  "component---src-templates-glossary-template-js": () => import("./../../../src/templates/glossaryTemplate.js" /* webpackChunkName: "component---src-templates-glossary-template-js" */),
  "component---src-templates-report-theme-template-js": () => import("./../../../src/templates/reportThemeTemplate.js" /* webpackChunkName: "component---src-templates-report-theme-template-js" */),
  "component---src-templates-tutorial-template-js": () => import("./../../../src/templates/tutorialTemplate.js" /* webpackChunkName: "component---src-templates-tutorial-template-js" */),
  "component---src-templates-viz-explorer-guide-template-js": () => import("./../../../src/templates/vizExplorerGuideTemplate.js" /* webpackChunkName: "component---src-templates-viz-explorer-guide-template-js" */)
}

